<!--
 * @Author: your name
 * @Date: 2020-12-11 10:19:32
 * @LastEditTime: 2021-02-05 17:55:07
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \scweb\src\views\service\outlets\index.vue
-->
<template>
  <div>
    <el-form label-width="100px" v-if="type!='home'">
      <el-row style="margin-top:20px;">
        <el-col :span="23">
          <div class="form-item-box">
            <el-form-item label="审批状态：">
              <el-select size="mini" style="width:130px;" v-model="search.approvalStatus" placeholder="选择审批状态">
                <el-option label="待审批" value="0"></el-option>
                <el-option label="已审批" value="1"></el-option>
                <el-option label="已退回" value="2"></el-option>
                <el-option label="撤回" value="3"></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="form-item-box">
            <el-form-item label="审批关系：">
              <el-select size="mini" style="width:130px;" v-model="search.approvalType" placeholder="选择审批关系">
                <el-option label="我参与的" value="0"></el-option>
                <el-option label="我提交的" value="1"></el-option>
                <el-option label="待我审批的" value="2"></el-option>
              </el-select>
            </el-form-item>
          </div>

          <div class="form-item-box">
            <el-form-item label="面额：">
              <el-input style="width:205px;float:left;" size="mini" placeholder="请输入面额" v-model="search.totalAmount"></el-input>
            </el-form-item>
          </div>
          <div class="form-item-box">
            <el-form-item label="模糊搜索：">
              <el-input style="width:205px;float:left;" size="mini" placeholder="请输入名称搜索" v-model="search.search"></el-input>
            </el-form-item>
          </div>
          <div class="form-item-box">
            <el-form-item label="审批时间：">
              <el-date-picker style="width:350px;margin:6px 0px 0px 0px;float:left;" v-model="search.approvalTime" size="mini" type="datetimerange" value-format="yyyy-MM-dd HH:mm:ss" start-placeholder="开始日期" end-placeholder="结束日期">
              </el-date-picker>
            </el-form-item>
          </div>
          <div class="form-item-box">
            <el-form-item label="提交时间：">
              <el-date-picker style="width:350px;margin:6px 0px 0px 0px;float:left;" v-model="search.submitTime" size="mini" type="datetimerange" value-format="yyyy-MM-dd HH:mm:ss" start-placeholder="开始日期" end-placeholder="结束日期">
              </el-date-picker>
            </el-form-item>
          </div>

          <el-button style="float:left;margin:6px 15px 0px" type="primary" size="mini" icon="el-icon-search" @click="handleCurrentChange(1)">查询</el-button>
          <el-button style="float:left;margin:6px 15px 0px 0px;" type="default" size="mini" @click="clearSearch()">重置</el-button>
        </el-col>
      </el-row>
    </el-form>
    <el-table stripe :data="list" style="box-shadow: 0 1px 4px 0 rgba(0,0,0,0.1);overflow:auto">
      <el-table-column type="index" align="center" label="序号" width="50"></el-table-column>
      <el-table-column prop="organizationName" align="center" label="提交部门" show-overflow-tooltip>
      </el-table-column>
      <el-table-column prop="submitName" align="center" label="提交人">
      </el-table-column>
      <el-table-column prop="batchType" align="center" label="审批类型">
        <template slot-scope="scope">
          <el-tag class="el-tag-style" size="mini" disable-transitions v-text="approvalTypeTag[scope.row.batchType]">
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="customerNumber" align="center" label="客户（人）">
        <template slot-scope="scope">
          <div v-show="[0,1,2].indexOf(scope.row.batchType) != -1" v-text="Number(scope.row.customerNumber)"></div>
          <div v-show="[3,8,9,10].indexOf(scope.row.batchType) != -1" v-text="Number(scope.row.vouchersCustomerNumber)"></div>
          <div v-show="[4,5,6,11].indexOf(scope.row.batchType) != -1" v-text="Number(scope.row.integralNumber)"></div>
          <div v-show="scope.row.batchType == 7" v-text="Number(scope.row.packageNumber)"></div>
        </template>
      </el-table-column>
      <el-table-column prop="totalAmount" align="center" label="总金额">
        <template slot-scope="scope">
          <div v-show="[0,1,2].indexOf(scope.row.batchType) != -1" v-text="Number(scope.row.totalAmount)"></div>
          <div v-show="[3,8,9,10].indexOf(scope.row.batchType) != -1" v-text="Number(scope.row.vouchersTotalAmount)"></div>
          <div v-show="[4,5,6,11].indexOf(scope.row.batchType) != -1" v-text="Number(scope.row.integralAmount)"></div>
          <div v-show="scope.row.batchType == 7" v-text="Number(scope.row.packageAmount)"></div>
        </template>
      </el-table-column>
      <el-table-column prop="submitTime" align="center" label="提交时间" show-overflow-tooltip>
      </el-table-column>
      <el-table-column prop="approvalStatus" align="center" label="审批状态">
        <template slot-scope="scope">
          <!-- <el-tag
                  size="mini"
                  :type="!scope.row.approvalStatus == 1 ? 'primary' : 'warning'"
                  disable-transitions>{{!scope.row.approvalStatus == 1 ?'已审批':'未审批'}}</el-tag> -->

          <el-tag class="el-tag-style" size="mini" disable-transitions :type="approvalStatusTag[scope.row.approvalStatus].type" v-text="approvalStatusTag[scope.row.approvalStatus].text">
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="approvalTime" align="center" label="审批时间" show-overflow-tooltip>
      </el-table-column>
      <el-table-column prop="address" align="left" label="操作">
        <template slot-scope="scope">
          <i class="el-icon-view btn-hover" @click="editDetail(scope.row.approvalId,1,scope.row.batchType)">查看 </i>
          <i class="el-icon-remove-outline btn-hover btn-red-hover" @click="editDetail(scope.row.approvalId,2,scope.row.batchType)" v-if="scope.row.approvalStatus == 0">审批</i>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination style="margin-top:10px;" v-show="pages>1 || type!='home'" layout="total, prev, pager, next,sizes" @size-change="handleSizeChange" @current-change="handleCurrentChange" background :page-size="pageSize" :current-page="pageNum" :total="total">
    </el-pagination>
    <el-dialog :destroy-on-close="true" title="审批详情" :visible.sync="isAddDialog" :lock-scroll="false" :close-on-click-modal="false" width="740px">
      <ApprovalDetail v-if="isAddDialog" :isAddDialog.sync="isAddDialog" :editId.sync="editId" :batchType.sync="batchType" :editType.sync="editType"></ApprovalDetail>
    </el-dialog>
  </div>

</template>
<script>
import { getCookies, approvalStatusTag } from '@/utils/utils'
import { getApprovalList } from '@/api/codes'
import ApprovalDetail from './components/approvalDetail.vue'

export default {
  name: 'approval',
  props: ['type'],
  components: {
    ApprovalDetail
  },
  data() {
    return {
      search: {
        approvalStatus: '',
        approvalTime: '',
        submitTime: '',
        approvalType: '',
        search: '',
        totalAmount: '',
      },
      editType: '',
      editId: '',
      batchType: '',
      approvalStatusTag: approvalStatusTag,
      approvalTypeTag: ['现油核销码', '现油前三后四', '现油充值', '期油发放', '积分-兑换码', '积分-前三后四', '积分-直接发放', '期油套餐发放', '油劵直接发放', '油劵核销码', '油劵前三后四', '积分-工号发放'],
      isAddDialog: false,

      list: [

      ],
      total: 0,//分页总页数
      pageNum: 1,//分页页数
      pageSize: 10,//每页显示多少
      pages: 1,
    }
  },
  watch: {
    isAddDialog() {
      this.getList();
    },
  },
  mounted() {
    this.getList();
  },
  methods: {
    handleSizeChange(val) {
      this.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getList();
    },
    getList() {
      let that = this;
      let param = {
        isPage: 1,
        pageNum: that.pageNum,
        pageSize: that.pageSize,
        sysId: getCookies('sysId'),
      };
      let search = that.search;
      param['search'] = search.search;
      param['approvalStatus'] = search.approvalStatus;
      param['totalAmount'] = search.totalAmount;
      param['approvalType'] = search.approvalType;
      param['submitStartTime'] = search.submitTime[0];
      param['submitEndTime'] = search.submitTime[1];
      param['approvalStartTime'] = search.approvalTime[0];
      param['approvalEndTime'] = search.approvalTime[1];
      if (this.type == 'home') {
        param.type = 1
        param.organizationId = getCookies('organizationId')
      }

      getApprovalList(param)
        .then((response) => {
          if (response.code == 200) {
            that.total = response.data.total;
            this.pages = response.data.pages
            that.list = response.data.records;
          } else {
            that.$message.error({
              title: '错误',
              message: response.msg,
              duration: 1500
            });
          }

        })
    },
    // type == 1 查看详情 type == 2 编辑
    editDetail(id, type, batchType) {
      this.editType = type;
      this.editId = id;
      this.batchType = batchType;
      this.isAddDialog = true;
    },
    clearSearch() {
      this.search = {
        approvalStatus: '',
        approvalTime: '',
        submitTime: '',
        approvalType: '',
        search: '',
        totalAmount: '',
      }
      this.total = 0
      this.pageNum = 1
      this.getList()
    }
  },
}
</script>
<style lang="scss" scoped>
@import "@/common/scss/base";
.form-item-box {
  height: 45px;
  float: left;
}

.el-form-item {
  margin: 0;
}
.date-picker-style {
  width: 205px;
  margin: 5px 0px 0px 0px;
  float: left;
}
</style>