<template>
  <el-row>
    <el-col :span="10">
      <div class="bar-title" v-text="'提交人：'+ ( items.organizationName || items.submitOrg ) + '-' + items.submitName"></div>
    </el-col>
    <el-col :span="3">
      <div class="bar-title" v-show="[0,1,2,3,7,8,9,10].indexOf(items.batchType) != -1">客户数：{{Number(items.customerNumber)}}</div>
      <div class="bar-title" v-show="[4,5,6,11].indexOf(items.batchType) != -1">客户数：{{Number(items.integralNumber)}}</div>
    </el-col>
    <el-col :span="4">
      <div class="bar-title" style="color:red;" v-show="[0,1,2,3,8,9,10].indexOf(items.batchType) != -1">总金额：{{Number(items.totalAmount)}}</div>
      <div class="bar-title" style="color:red;" v-show="[4,5,6,11].indexOf(items.batchType) != -1">总金额：{{Number(items.integralAmount)}}</div>
    </el-col>
    <el-col :span="7">
      <div class="bar-title" style="text-align: right;">提交时间：{{items.submitTime}}</div>
    </el-col>
    <el-col :span="24">
      <div class="list-box">
        <el-table border stripe :data="list" v-if="[0,1,2].indexOf(batchType) != -1">
          <el-table-column prop="organizationCode" align="center" label="序号" width="100">
            <template slot-scope="scope">
              <div>{{scope.$index+1}}</div>
            </template>
          </el-table-column>
          <el-table-column prop="usedName" align="center" label="客户名称" width="200"></el-table-column>
          <el-table-column prop="amount" align="center" label="面额" width="238"></el-table-column>
          <el-table-column prop="usedPhone" align="center" label="手机号" width="160"></el-table-column>
        </el-table>
        <el-table border stripe :data="list" v-if="[3,8,9,10].indexOf(batchType) != -1">
          <el-table-column prop="organizationCode" align="center" label="序号" width="100">
            <template slot-scope="scope">
              <div>{{scope.$index+1}}</div>
            </template>
          </el-table-column>
          <el-table-column prop="customerName" align="center" label="客户名称" width="200"></el-table-column>
          <el-table-column prop="actualAmount" align="center" label="面额" width="238"></el-table-column>
          <el-table-column prop="bindPhone" align="center" label="手机号" width="160"></el-table-column>
        </el-table>
        <el-table border stripe :data="list" v-if="[4,5,6,11].indexOf(batchType) != -1">
          <el-table-column prop="organizationCode" align="center" label="序号" width="100">
            <template slot-scope="scope">
              <div>{{scope.$index+1}}</div>
            </template>
          </el-table-column>
          <el-table-column prop="customerName" align="center" label="客户名称" width="200"></el-table-column>
          <el-table-column prop="exchangeAmount" align="center" label="面额" width="238"></el-table-column>
          <el-table-column prop="usedPhone" align="center" label="手机号" width="160"></el-table-column>
        </el-table>
        <el-table border stripe :data="list" v-if="batchType == 7">
          <el-table-column prop="organizationCode" align="center" label="序号" width="100">
            <template slot-scope="scope">
              <div>{{scope.$index+1}}</div>
            </template>
          </el-table-column>
          <el-table-column prop="customerName" align="center" label="客户名称" width="200"></el-table-column>
          <el-table-column prop="packageName" align="center" label="套餐名称" width="238"></el-table-column>
          <el-table-column prop="customerPhone" align="center" label="手机号" width="160"></el-table-column>
        </el-table>
      </div>
      </div>
    </el-col>
    <el-col :span="24" border style="width:100%">
      <div class="table-title-box">
        <div class="table-title-bar">
          <div class="table-label">步骤</div>
          <div class="table-label">处理人</div>
          <div class="table-label">处理意见</div>
        </div>
        <div class="table-title-bar">
          <div class="table-label">提交</div>
          <div class="table-label" v-text="( items.organizationName || items.submitOrg ) + '-' + items.submitName">处理人</div>

          <div class="table-label"></div>
        </div>
        <div class="table-title-bar" style="height:60px;">
          <div class="table-label">核验</div>
          <div class="table-label" v-text="items.approvalOrg + '-' + items.approvalName">处理人</div>
          <div class="table-label">
            <el-input class="table-input" v-if="remark || editType == 2" type="textarea" :disabled="editShow" v-model="remark" placeholder="请输入意见（200字内），不填默认值为“同意”或“退回”！" maxlength="200" />
          </div>
        </div>
      </div>
    </el-col>
    <el-col :span="24">
      <el-button size="mini" type="primary" v-debounce="onSubmitOne" v-if="!editShow">同意发放</el-button>
      <el-button size="mini" type="primary" v-debounce="onSubmitTwo" v-if="!editShow">全部退回</el-button>
      <el-button size="mini" @click="dialogClose">关闭窗口</el-button>
    </el-col>
  </el-row>
</template>
<script>
import { getApprovalInfo, getVoucherApprovalInfo, agreeOrBack } from '@/api/codes';
import { getPackageApprovalInfo } from '@/api/setting';
// 批次类型(0.核销码,1.实名发放,2.直接充值,3.折扣券)
export default {
  props: ['isAddDialog', 'editId', 'batchType', 'editType'],
  data() {

    return {
      items: {},
      remark: '',
      list: [],

    }
  },
  computed: {
    editShow() {
      return this.editType == 1
    }
  },
  mounted() {
    this.getDateil();
  },
  methods: {
    dialogClose() {
      this.$emit('update:isAddDialog', false)
    },
    onSubmitOne() {
      this.onSubmit(1)
    },
    onSubmitTwo() {
      this.onSubmit(2)
    },
    onSubmit(status) {
      let that = this;
      let remark = that.remark;

      if (status == 2 && !remark) {
        that.$message({
          title: '错误',
          type: 'error',
          message: '请填写退回意见',
          duration: 1500
        });
        return false;
      }
      if (remark == '' || remark == undefined || String(remark).length < 1) {
        remark = status == 1 ? '同意；' : '退回；';
      }

      let params = {
        'approvalId': that.editId,
        'approvalStatus': status,
        'remark': remark
      };
      agreeOrBack(params)
        .then((response) => {
          if (response.code == 200) {
            that.dialogClose();
          }
          that.$message({
            title: '错误',
            type: response.code == 200 ? 'success' : 'error',
            message: response.msg || response.error_msg || response.message,
            duration: 1500
          });
        })

    },
    getDateil() {
      let that = this;
      let param = {
        'approvalId': this.editId
      };
      if (that.batchType == 7) {
        getPackageApprovalInfo(this.editId)
          .then((response) => {
            if (response.code == 200) {
              that.items = response.data
              that.remark = response.data.remark;
              if (response.data.backReason) {
                that.remark = response.data.backReason;
              }

              that.list = response.data.list;
            }

          })
        // batchType == 7时阻止执行
        return false;
      }
      if ([3, 8, 9, 10].indexOf(that.batchType) != -1) {
        getVoucherApprovalInfo(param)
          .then((response) => {
            if (response.code == 200) {
              that.items = response.data
              that.remark = response.data.remark;
              if (response.data.backReason) {
                that.remark = response.data.backReason;
              }

              that.list = response.data.voucherVO;
            }

          })
        // batchType == 3时阻止执行
        return false;
      } else {
        getApprovalInfo(param)
          .then((response) => {
            if (response.code == 200) {
              that.items = response.data
              that.remark = response.data.remark;
              if (response.data.backReason) {
                that.remark = response.data.backReason;
              }
              that.list = response.data.listVOS;
              if ([4, 5, 6, 11].indexOf(response.data.batchType) != -1) {
                that.list = response.data.infoIntegraList;
              }
              console.log('that.list');
              console.log(that.list);
            }
          })
      }
    },
  },

}
</script>
<style scoped>
.table-title-box {
  border-bottom: 1px solid #b4b4b4;
  width: 100%;
  margin: 0 auto 20px;
}
.table-title-bar:first-child {
  background-color: #cecece;
}
.table-title-bar {
  display: inline-flex;
  align-items: flex-start;
  text-align: left;
  width: 100%;
  margin: 0 auto;
  color: #333333;

  flex-direction: row;
  border-top: 1px solid #b4b4b4;
  border-right: 1px solid #b4b4b4;
  height: 40px;
  line-height: 40px;
}
.table-input {
  width: 100%;
  margin: 0;
  border-radius: 0px;
}
.table-label {
  height: 100%;
  padding: 0px 0;
  border-left: 1px solid #b4b4b4;
  width: 100%;
  text-align: center;
}
.table-label:first-child {
  width: 30%;
}
.el-form-item {
  text-align: left;
}
.bar-title {
  letter-spacing: 1px;
  color: #333333;
  margin: 10px 0px;
  font-size: 12px;
  text-align: left;
  float: left;
  display: inline-block;
  position: relative;
}
.list-box {
  position: relative;
  display: inline-block;
  width: 100%;
  float: left;
  min-height: 200px;
  max-height: 450px;
  margin: 0px 0px 20px 0px;
  overflow: hidden;
  overflow-y: auto;
  border: 1px solid #b4b4b4;
}
</style>